//./frontend/src/cardClasses.js

export const cardClasses = {
  Cultist: {
    force: 3,
    magic: 3,
    stealth: 3,
    luck: 3,
    rarity: 50,
  },
  Assassin: {
    force: 3,
    magic: 3,
    stealth: 8,
    luck: 6,
    rarity: 14,

  },
  Brute: {
    force: 10,
    magic: 3,
    stealth: 3,
    luck: 4,
    rarity: 10,

  },
  Banker: {
    force: 2,
    magic: 2,
    stealth: 2,
    luck: 3,
    rarity: 6,
  },
  Spy: {
    force: 2,
    magic: 2,
    stealth: 3,
    luck: 2,
    rarity: 6,
  },
    Politician: {
    force: 3,
    magic: 2,
    stealth: 2,
    luck: 2,
    rarity: 6,
  },
    Healer: {
    force: 2,
    magic: 3,
    stealth: 2,
    luck: 2,
    rarity: 6,
  },

    Secret: {
    force: 1,
    magic: 1,
    stealth: 1,
    luck: 1,
    rarity: 2,
  },
  // Add more card classes here as needed
};
