/* frontend/src/components/Controls.js */


import React, { useState, useCallback, useEffect } from 'react';
import './Controls.css';
import { slotNames } from '../constants';

const Controls = ({ onAction, currentPlayer, gameState }) => {
  const [currentAction, setCurrentAction] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedOpponentSlot, setSelectedOpponentSlot] = useState(null);
  const isDisabled = currentPlayer === 2;


  const handleActionChoice = useCallback((action) => {
    setCurrentAction(action);
  }, []);

  const handleSlotChoice = useCallback((slot) => {
    setSelectedSlot(slot);
  }, []);

  const handleOpponentSlotChoice = useCallback((slot) => {
    setSelectedOpponentSlot(slot);
  }, []);

  const handleAttributeChoice = useCallback(
    (attribute) => {
      const attackerSlotIndex = slotNames.indexOf(selectedSlot);
      const defenderSlotIndex = slotNames.indexOf(selectedOpponentSlot);

      onAction({
        type: currentAction === 'addCard' ? 'ADD_CARD_TO_BATTLE_DECK' : 'ATTACK',
        payload: {
          playerId: currentPlayer,
          attribute: attribute,
          attackerSlotIndex: attackerSlotIndex,
          defenderSlotIndex: defenderSlotIndex,
        },
      });
      setCurrentAction(null);
      setSelectedSlot(null);
      setSelectedOpponentSlot(null);
    },
    [currentPlayer, onAction, currentAction, selectedSlot, selectedOpponentSlot, slotNames]
  );

const renderActionButtons = () => {
  if (!currentAction) {
    return (
      <>
        {gameState[`player${currentPlayer}`].reserveDeck.length > 0 && (
          <button
          disabled={isDisabled}
          onClick={() => handleActionChoice('addCard')}>Add Card to Deck</button>
        )}
        <button 
          disabled={isDisabled}
          onClick={() => handleActionChoice('attack')}>Attack</button>
      </>
    );
  }
};


const renderSlotButtons = () => {
if ((currentAction === 'addCard' && !selectedSlot) || (currentAction === 'attack' && selectedOpponentSlot && !selectedSlot)) { // Changed condition
    return (
      <>
        <button
          disabled={isDisabled}
          className="back-button"
          onClick={() => setCurrentAction(null)}
        >
          Back
        </button>
        {slotNames.map((slot) => {
          const battleDeck =
            gameState[`player${currentPlayer}`].battleDeck[
              slotNames.indexOf(slot)
            ];
          if (currentAction === 'addCard' || battleDeck.length > 0) {
            return (
              <button
                disabled={isDisabled}
                key={`slot-${slot}`}
                onClick={() => handleSlotChoice(slot)}
              >
                {currentAction === 'addCard'
                  ? `Add card to ${slot}`
                  : `Attack from ${slot}`}
              </button>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }
};



const renderOpponentSlotButtons = () => {
  if (currentAction === 'attack' && !selectedSlot && !selectedOpponentSlot) { // Changed condition
    return (
      <>
        <button
          disabled={isDisabled}
          className="back-button"
          onClick={() => setSelectedSlot(null)}
        >
          Back
        </button>
        {slotNames.map((slot) => {
          const battleDeck =
            gameState[`player${currentPlayer === 1 ? 2 : 1}`].battleDeck[
              slotNames.indexOf(slot)
            ];
          if (battleDeck.length > 0) {
            return (
              <button
                disabled={isDisabled}
                key={`opponent-slot-${slot}`}
                onClick={() => handleOpponentSlotChoice(slot)}
              >
                {`Attack opponent's ${slot}`}
              </button>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }
};


const renderAttributeButtons = () => {
  if (currentAction === 'attack' && selectedSlot && selectedOpponentSlot) {
    return (
      <>
        <button
        disabled={isDisabled}
        className="back-button" onClick={() => setSelectedOpponentSlot(null)}>Back</button>
        {['force', 'magic', 'stealth', 'luck'].map((attribute) => (
          <button
          disabled={isDisabled}
          key={`attribute-${attribute}`} onClick={() => handleAttributeChoice(attribute)}>
            Attack with {attribute}
          </button>
        ))}
      </>
    );
  }
};

useEffect(() => {
  if (currentAction === 'addCard' && selectedSlot) {
    handleAttributeChoice(null);
  }
}, [currentAction, selectedSlot]);


  return (
    <div className={`controls controls-player${currentPlayer}`}>
      {renderActionButtons()}
      {renderSlotButtons()}
      {renderOpponentSlotButtons()}
      {renderAttributeButtons()}
    </div>
  );
};

export default Controls;



