// frontend/src/components/BattleCard.js
import React from 'react';
import './BattleCard.css';

const BattleCard = ({ playerId, cardStack, index }) => {
  const emptySlot = (
    <div className="empty-slot">
      <span>Empty</span>
    </div>
  );

  const isEmpty = cardStack.length === 0;
  const topCard = cardStack[cardStack.length - 1];

  const showClassName = playerId === 1; // Only show class name and attributes for player 1's cards

  const battleCard = !isEmpty ? (
    <div
      className={`card-stack${playerId === 2 ? ' opponent-card' : ''}`}
      style={{ '--delay': `${index * 0.1}s` }}
    >
      <div className={`battle-card${playerId === 2 ? ' opponent-card' : ''}`}>
        {showClassName && <div className="card-class">{topCard.className}</div>}
        {showClassName && <div className="card-attribute">Force: {topCard.force}</div>}
        {showClassName && <div className="card-attribute">Magic: {topCard.magic}</div>}
        {showClassName && <div className="card-attribute">Stealth: {topCard.stealth}</div>}
        {showClassName && <div className="card-attribute">Luck: {topCard.luck}</div>}
      </div>
      {cardStack.length > 1 && (
        <div className="card-count">
          <span>{cardStack.length} cards</span>
        </div>
      )}
    </div>
  ) : (
    emptySlot
  );

  return (
    <div className="card-slot">
      {battleCard}
    </div>
  );
};

export default BattleCard;

