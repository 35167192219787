// frontend/src/components/Graveyard.js
import React from 'react';
import './Graveyard.css';

const Graveyard = ({ graveyard }) => {
  return (
    <div className="graveyard">
      <h3>Graveyard</h3>
      <div className="graveyard-card-count">{graveyard.length}</div>
    </div>
  );
};

export default Graveyard;
