// // frontend/src/components/PlayerArea.js
// import React from 'react';
// import ReserveDeck from './ReserveDeck';
// import BattleDeck from './BattleDeck';
// import Graveyard from './Graveyard';
// import './PlayerArea.css';


// const PlayerArea = ({ player, playerId, currentPlayer }) => {
//   const isActive = playerId === currentPlayer;
//   return (
//     <div className={`player-area ${isActive ? 'active' : ''}`}>
//       <h2>Player {playerId}</h2>
//       <div className="decks-container">
//         <ReserveDeck reserveDeck={player.reserveDeck} />
//         <BattleDeck battleDeck={player.battleDeck} />
//         <Graveyard graveyard={player.graveyard} />
//       </div>
//     </div>
//   );
// };


// export default PlayerArea;

import React from 'react';
import ReserveDeck from './ReserveDeck';
import BattleDeck from './BattleDeck';
import Graveyard from './Graveyard';
import './PlayerArea.css';

const PlayerArea = ({ player, playerId, currentPlayer, playerName }) => {
  const isActive = playerId === currentPlayer;
  return (
    <div className={`player-area ${isActive ? 'active' : ''}`}>
      <h2>{playerName}</h2>
      <div className="decks-container">
        <ReserveDeck reserveDeck={player.reserveDeck} gold={player.gold} />
        <BattleDeck playerId={playerId} battleDeck={player.battleDeck} />
        <Graveyard graveyard={player.graveyard} />
      </div>
    </div>
  );
};

export default PlayerArea;
