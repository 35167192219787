import React from 'react';
import './App.css';
import GameContainer from './components/GameContainer';
import PlayerArea from './components/PlayerArea';
import Card from './components/BattleCard';
import BattleDeck from './components/BattleDeck';
import ReserveDeck from './components/ReserveDeck';
import Graveyard from './components/Graveyard';
import Controls from './components/Controls';

function App() {
  return (
    <div className="App">
      <GameContainer>
        <PlayerArea>
          <BattleDeck>
            <Card />
          </BattleDeck>
          <ReserveDeck />
          <Graveyard>
            <Card />
          </Graveyard>
        </PlayerArea>
        <PlayerArea>
          <BattleDeck>
            <Card />
          </BattleDeck>
          <ReserveDeck />
          <Graveyard>
            <Card />
          </Graveyard>
        </PlayerArea>
        <Controls />
      </GameContainer>
    </div>
  );
}

export default App;
