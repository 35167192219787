
/* frontend/src/ai.js */
import { slotNames } from './constants';

const getValidAddCardMoves = (gameState, currentPlayer) => {
  const moves = [];

  if (gameState[`player${currentPlayer}`].reserveDeck.length > 0) {
    for (let i = 0; i < 6; i++) {
      moves.push({
        type: 'ADD_CARD_TO_BATTLE_DECK',
        payload: {
          playerId: currentPlayer,
          attackerSlotIndex: i,
        },
      });
    }
  }

  return moves;
};

const getValidAttackMoves = (gameState, currentPlayer) => {
  const opponentPlayer = currentPlayer === 1 ? 2 : 1;
  const moves = [];

  for (let i = 0; i < 6; i++) {
    if (gameState[`player${currentPlayer}`].battleDeck[i].length > 0) {
      for (let j = 0; j < 6; j++) {
        if (gameState[`player${opponentPlayer}`].battleDeck[j].length > 0) {
          for (const attribute of ['force', 'magic', 'stealth', 'luck']) {
            moves.push({
              type: 'ATTACK',
              payload: {
                playerId: currentPlayer,
                attackerSlotIndex: i,
                defenderSlotIndex: j,
                attribute: attribute,
              },
            });
          }
        }
      }
    }
  }

  return moves;
};

const makeAiMove = (gameState, currentPlayer) => {
  const addCardMoves = getValidAddCardMoves(gameState, currentPlayer);
  const attackMoves = getValidAttackMoves(gameState, currentPlayer);
  const validMoves = [...addCardMoves, ...attackMoves];

  if (validMoves.length > 0) {
    const selectedMove = validMoves[Math.floor(Math.random() * validMoves.length)];
    return selectedMove;
  }
};

export { makeAiMove };
