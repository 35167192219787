// frontend/src/components/BattleDeck.js
import React from 'react';
import './BattleDeck.css';
import BattleCard from './BattleCard';
import { slotNames } from '../constants'; // Import slotNames from constants




const BattleDeck = ({ battleDeck, playerId }) => {
  return (
    <div className="battle-deck">
      <h3>Battle Deck</h3>
      <div className="cards-row">
        {battleDeck.map((cardStack, index) => (
          <div key={`slot-${index}`} className="slot">
            <div className="slot-name">{slotNames[index]}</div>
            <BattleCard playerId={playerId} cardStack={cardStack} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BattleDeck;
