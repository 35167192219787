import { cardClasses } from '../cardClasses';

const generateCardByRarity = (cardClassNames) => {
  const raritySum = cardClassNames.reduce(
    (acc, className) => acc + cardClasses[className].rarity,
    0
  );
  let randomValue = Math.random() * raritySum;
  let selectedCardClassName;

  for (const className of cardClassNames) {
    randomValue -= cardClasses[className].rarity;
    if (randomValue <= 0) {
      selectedCardClassName = className;
      break;
    }
  }

  return selectedCardClassName;
};

export const generateCard = () => {
  const cardClassNames = Object.keys(cardClasses);
  const randomCardClassName = generateCardByRarity(cardClassNames);
  const cardClass = cardClasses[randomCardClassName];
  const card = {
    className: randomCardClassName.toLowerCase(),
    force: Math.ceil(Math.random() * 1.25 * cardClass.force),
    magic: Math.ceil(Math.random() * 1.25 * cardClass.magic),
    stealth: Math.ceil(Math.random() * 1.25 * cardClass.stealth),
    luck: Math.ceil(Math.random() * 1.25 * cardClass.luck),
  };
  return card;
};

export const generateReserveDeck = () => {
  const deck = [];
  for (let i = 0; i < 50; i++) {
    deck.push(generateCard());
  }
  return deck;
};

export const countCardClasses = (deck) => {
  const classCount = {};

  deck.forEach((card) => {
    if (!classCount[card.className]) {
      classCount[card.className] = 0;
    }
    classCount[card.className]++;
  });

  return classCount;
};

export const generateInitialBattleCards = () => {
  const initialCards = [];
  const secretCardClassNames = Object.keys(cardClasses).filter(className => className.toLowerCase() === 'secret');

  // Add 3 secret cards
  for (let i = 0; i < 3; i++) {
    const secretCardClassName = generateCardByRarity(secretCardClassNames);
    const cardClass = cardClasses[secretCardClassName];
    initialCards.push({
      className: secretCardClassName.toLowerCase(),
      force: Math.ceil(Math.random() * 1.25 * cardClass.force),
      magic: Math.ceil(Math.random() * 1.25 * cardClass.magic),
      stealth: Math.ceil(Math.random() * 1.25 * cardClass.stealth),
      luck: Math.ceil(Math.random() * 1.25 * cardClass.luck),
    });
  }

  // Add 9 random cards
  const otherCardClassNames = Object.keys(cardClasses).filter(className => className.toLowerCase() !== 'secret');
  for (let i = 0; i < 9; i++) {
    initialCards.push(generateCard());
  }

  // Shuffle the initialCards array
  for (let i = initialCards.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [initialCards[i], initialCards[j]] = [initialCards[j], initialCards[i]];
  }

  return initialCards;
};
