
// /frontend/src/components/GameContainer.js
import React, { useState, useEffect, useCallback } from 'react';
import PlayerArea from './PlayerArea';
import Controls from './Controls';
import Notification from './Notification';
import { slotNames } from '../constants';
import { makeAiMove } from '../ai';
import useNotification from '../useNotification';
import { cardClasses } from '../cardClasses';
import { generateCard, generateReserveDeck, countCardClasses, generateInitialBattleCards } from './CardGenerator'; 


const processPoliticianCard = (currentPlayerState) => {
  if (currentPlayerState.reserveDeck.length === 0) return;

  const randomSlotIndex = Math.floor(Math.random() * 6);
  currentPlayerState.battleDeck[randomSlotIndex].push(currentPlayerState.reserveDeck.shift());
};

const processHealerCard = (currentPlayerState) => {
  if (currentPlayerState.graveyard.length === 0) return;

  const randomSlotIndex = Math.floor(Math.random() * 6);
  currentPlayerState.battleDeck[randomSlotIndex].push(currentPlayerState.graveyard.pop());
};

//Setting up a const to count gold generating cards
const countSecretCards = (battleDeck) => {
  let count = 0;
  battleDeck.forEach(slot => {
    slot.forEach(card => {
      if (card.className === "secret" || card.className === "banker") {
        count++;
      }
    });
  });
  return count;
};

const CARD_MOVE_COST = 3; // Define the card move cost here


const GameContainer = () => {

const [gameState, setGameState] = useState({
player1: {
reserveDeck: [],
battleDeck: Array.from({ length: 6 }, () => []), // Initialize with arrays containing empty arrays
graveyard: [],
},
player2: {
reserveDeck: [],
battleDeck: Array.from({ length: 6 }, () => []), // Initialize with arrays containing empty arrays
graveyard: [],
},
});
const [currentPlayer, setCurrentPlayer] = useState(1);

// const [notification, setNotification] = useState('');

  const [aiThinking, setAiThinking] = useState(false);

  const playerNames = {
    1: 'Player 1',
    2: 'Opponent',
  };

  const [notification, showNotification] = useNotification(); // Use the custom hook here

  useEffect(() => {
    console.log(`It's player ${currentPlayer} turn`);
  }, [currentPlayer]);


useEffect(() => {
  const initialGameState = {
    player1: {
      gold: 0, // Initialize with 0, will be updated later
      reserveDeck: generateReserveDeck(),
      battleDeck: Array.from({ length: 6 }, () => []), // Initialize with arrays containing empty arrays
      graveyard: [],
    },
    player2: {
      gold: 0, // Initialize with 0, will be updated later
      reserveDeck: generateReserveDeck(),
      battleDeck: Array.from({ length: 6 }, () => []), // Initialize with arrays containing empty arrays
      graveyard: [],
    },
  };

const initialPlayer1BattleCards = generateInitialBattleCards();
const initialPlayer2BattleCards = generateInitialBattleCards();
for (let i = 0; i < 6; i++) {
  initialGameState.player1.battleDeck[i].push(initialPlayer1BattleCards[i * 2]);
  initialGameState.player1.battleDeck[i].push(initialPlayer1BattleCards[i * 2 + 1]);
  initialGameState.player2.battleDeck[i].push(initialPlayer2BattleCards[i * 2]);
  initialGameState.player2.battleDeck[i].push(initialPlayer2BattleCards[i * 2 + 1]);
}

  initialGameState.player1.gold = 3;
  initialGameState.player2.gold = 3;

  // Count card classes in both reserve decks
  const player1CardClassCount = countCardClasses(initialGameState.player1.reserveDeck);
  const player2CardClassCount = countCardClasses(initialGameState.player2.reserveDeck);

  console.log("Player 1 card class counts:", player1CardClassCount);
  console.log("Player 2 card class counts:", player2CardClassCount);

  setGameState(initialGameState);
}, []);







const handleAction = useCallback((action) => {

// Verify active player
if (action.payload.playerId !== currentPlayer) {
return;
}

// Clone gameState and get player states
  const newGameState = { ...gameState };
  const currentPlayerState = newGameState[`player${action.payload.playerId}`];
  const opponentPlayerState = newGameState[`player${action.payload.playerId === 1 ? 2 : 1}`];



const processCardEffects = (playerState) => {
  const topCards = playerState.battleDeck.map(slot => slot[slot.length - 1]);

  topCards.forEach(card => {
    if (card && card.className === "banker") {
      playerState.gold += 1;
      console.log(`gold was added`);

    }
  });

  topCards.forEach(card => {
    if (card && card.className === "politician") {
      processPoliticianCard(playerState);
            console.log(`card was added from reserve deck`);

    }
  });

  topCards.forEach(card => {
    if (card && card.className === "healer") {
      processHealerCard(playerState);
            console.log(`card was revived`);

    }
  });
};



// Process actions based on type
switch (action.type) {
    case 'ADD_CARD_TO_BATTLE_DECK':
      // Check if player has enough gold
      if (currentPlayerState.gold < CARD_MOVE_COST) {
        showNotification(`Not enough gold to move card`);
        return;
      }
      // Remove card from reserveDeck
      const cardToAdd = currentPlayerState.reserveDeck.shift();
      // Add card to battleDeck
      currentPlayerState.battleDeck[action.payload.attackerSlotIndex].push(cardToAdd);
      // Subtract card move cost from player's gold
      currentPlayerState.gold -= CARD_MOVE_COST;
      // Notify user
      showNotification(`Card added to deck (Gold: ${currentPlayerState.gold})`);
      console.log(`Player ${action.payload.playerId} added a card to ${slotNames[action.payload.attackerSlotIndex]}`);

      break;

case 'ATTACK':

console.log("action: ", action);


// Check if attacker slot is empty
if (!currentPlayerState.battleDeck[action.payload.attackerSlotIndex].length) {
showNotification('Cannot attack from an empty slot');
return;
}
// Check if defender slot is empty
if (!opponentPlayerState.battleDeck[action.payload.defenderSlotIndex].length) {
showNotification('Cannot attack an empty slot');
return;
}
//  Get attacker and defender slots
const attackerSlot = currentPlayerState.battleDeck[action.payload.attackerSlotIndex];
const defenderSlot = opponentPlayerState.battleDeck[action.payload.defenderSlotIndex];
// Get top cards from slots
const attackerCard = attackerSlot && attackerSlot[attackerSlot.length - 1];
const defenderCard = defenderSlot && defenderSlot[defenderSlot.length - 1];
// Get attribute for comparison
const attribute = action.payload.attribute;

        // Compare cards and update graveyard
const attackingPlayer = playerNames[action.payload.playerId];


if (attackerCard && defenderCard && attackerCard[attribute] > defenderCard[attribute]) {
  opponentPlayerState.graveyard.push(defenderCard);
  defenderSlot.pop();
  showNotification(`${attackingPlayer} is victorious`);
  console.log(`${attackingPlayer} attacked ${slotNames[action.payload.defenderSlotIndex]} from ${slotNames[action.payload.attackerSlotIndex]} with ${attribute}`);

} else if (attackerCard && defenderCard && attackerCard[attribute] < defenderCard[attribute]) {
  currentPlayerState.graveyard.push(attackerCard);
  attackerSlot.pop();
  showNotification(`${attackingPlayer} failed and died trying`);
  console.log(`${attackingPlayer} attacked ${slotNames[action.payload.defenderSlotIndex]} from ${slotNames[action.payload.attackerSlotIndex]} with ${attribute} and failed`);
} else {
  showNotification(`${attackingPlayer} failed, both live to fight another day`);
  console.log(`${attackingPlayer} attacked ${slotNames[action.payload.defenderSlotIndex]} from ${slotNames[action.payload.attackerSlotIndex]} with ${attribute} and both survived`);

}

  // Check for game win condition
  if (opponentPlayerState.graveyard.length === 50) {
    showNotification(`Player ${action.payload.playerId} wins the game!`);
  }

// Check for game lose condition
if (currentPlayerState.battleDeck.every(slot => slot.length === 0)) {
  showNotification(`Player ${playerNames[currentPlayer === 1 ? 2 : 1]} wins the game!`);
  console.log(`game over - all cards are gone`);
}


  break;
default:
  break;
  }

// Call processCardEffects function
processCardEffects(currentPlayerState);
console.log("currentPlayerState: ", gameState.player2);
console.log("yourPlayerState: ", gameState.player1);


// Update gameState with new state
setGameState(newGameState);

// Count secret cards and update gold count for both players
const currentPlayerSecretCount = countSecretCards(currentPlayerState.battleDeck);
const opponentPlayerSecretCount = countSecretCards(opponentPlayerState.battleDeck);

// Update gold count only for active player on their turn
if (action.payload.playerId === currentPlayer) {
currentPlayerState.gold += currentPlayerSecretCount;
}




// Switch active player
setCurrentPlayer((currentPlayer) => (currentPlayer === 1 ? 2 : 1));



}, [gameState, currentPlayer]);



// If the current player is the AI opponent, make a move
useEffect(() => {
  if (currentPlayer === 2 && !aiThinking) {
    setAiThinking(true);

    // Add a 2-second delay before the AI makes its move
    setTimeout(() => {
      const aiMove = makeAiMove(gameState, 2); // Make a move for player 2 (the AI)
      handleAction(aiMove);
      setAiThinking(false); // Reset the AI's thinking status
    }, 2000); // Add a 2-second delay for AI move
  }
}, [currentPlayer, aiThinking, handleAction]);


  return (
    <div className="game-container">
      <PlayerArea player={gameState.player2} playerId={2} currentPlayer={currentPlayer} playerName="Opponent" />
      <PlayerArea player={gameState.player1} playerId={1} currentPlayer={currentPlayer} playerName="You" />
      <Controls onAction={handleAction} currentPlayer={currentPlayer} gameState={gameState} />
      {notification && <Notification message={notification} />}
    </div>
  );
};

export default GameContainer;
