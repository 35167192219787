// src/useNotification.js
import { useState } from 'react';

const useNotification = () => {
  const [notification, setNotification] = useState('');

  const showNotification = (message, timeout = 2000) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, timeout);
  };

  return [notification, showNotification];
};

export default useNotification;
