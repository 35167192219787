// frontend/src/components/ReserveDeck.js
import React from 'react';
import './ReserveDeck.css';

const ReserveDeck = ({ reserveDeck, gold }) => {
  return (
    <div className="reserve-deck">
      <h3>Reserve</h3>
      <div className="reserve-card-count">{reserveDeck.length}</div>
      <div className="gold-counter">Gold: {gold}</div>
    </div>
  );
};

export default ReserveDeck;
